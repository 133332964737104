<template>
<div :class="classes">
  <collapse v-for="(address, key) in pessoa.addresses" :key="'address-' + key" :collapse="true" :title="key > 0 ? 'Endereço ' + (key+1) : 'Endereço principal'">
    <e-row mr>
      <!--<e-col style="max-width: 180px">
        <erp-s-field label="País">
          <erp-select disable v-model="address.countryId" :options="[
                    {label: 'Brasil', value: 1},
                ]"/>
        </erp-s-field>
      </e-col>-->
      <e-col style="max-width: 280px">
        <erp-s-field label="CEP">
          <cep-input @cep="() => focusByClass('addressNumber' + key)" :person="pessoa" :address="address" v-model="address.zip" />
        </erp-s-field>
      </e-col>
      <e-col style="max-width: 260px">
        <erp-s-field label="UF">
          <uf-select :city="address.city" v-model="address.state" @preventCitySelected="(city) => address.city = city" />
        </erp-s-field>
      </e-col>
      <e-col style="max-width: 260px">
        <erp-s-field label="Cidade">
          <cidade-select :uf="address.state" v-model="address.city" />
        </erp-s-field>
      </e-col>
    </e-row>
    <e-row mr>
      <e-col>
        <erp-s-field label="Endereço">
          <erp-input v-model="address.address"/>
        </erp-s-field>
      </e-col>
      <e-col style="max-width: 120px">
        <erp-s-field label="Número">
          <erp-input :class="'addressNumber' + key" v-model="address.number"/>
        </erp-s-field>
      </e-col>
      <e-col>
        <erp-s-field label="Bairro">
          <erp-input v-model="address.district"/>
        </erp-s-field>
      </e-col>
      <e-col>
        <erp-s-field label="Complemento">
          <erp-input v-model="address.complement"/>
        </erp-s-field>
      </e-col>
    </e-row>
    <e-row mr>
      <e-col style="max-width: 200px">
        <tipo-endereco-select v-model="address.purpose" />
      </e-col>
      <e-col v-if="key >= 1" class="flex items-center">
        <a class="text-negative" @click="removeEndereco(address)">remover este endereço</a>
      </e-col>
    </e-row>
  </collapse>
  <e-btn label="Adicionar novo endereço" @click="adicionarEndereco" />
</div>
</template>

<script>
import CidadeSelect from "components/pessoa/components/include/CidadeSelect"
import UfSelect from "components/pessoa/components/include/UfSelect"
import CepInput from "components/pessoa/components/include/CepInput"
import Collapse from "components/globalconfig/components/include/Collapse"
import {ErpInput, ErpSelect, ErpSField} from "@/plugins/uloc-erp"
import ERow from "components/layout/components/Row"
import ECol from "components/layout/components/Col"
import {mockEndereco} from "components/pessoa/mock/endereco"
import TipoEnderecoSelect from "components/pessoa/components/include/TipoEnderecoSelect"

export default {
  name: "EnderecoV2",
  props: {
    pessoa: {required: true},
    css: {required: false}
  },
  components: {
    CidadeSelect,
    UfSelect,
    CepInput,
    Collapse,
    ErpInput,
    ErpSField,
    ERow,
    ECol,
    // UTooltip,
    // ErpSelect,
    TipoEnderecoSelect
  },
  computed: {
    hasEndereco (){
      return this.pessoa.addresses.length || this.semEndereco
    },
    classes () {
      if (this.css) {
        return this.css
      }
      return 'e-input-modern label-size-1 size1'
    }
  },
  methods: {
    focusByClass (css) {
      setTimeout(() => {
        const seletor = document.querySelector('.' + css + ' input')
        seletor && seletor.focus()
      }, 100)
    },
    adicionarEndereco () {
      this.pessoa.addresses.push(JSON.parse(JSON.stringify(mockEndereco)))
    },
    removeEndereco (endereco) {
      this.$uloc.dialog({
        title: 'Você está prestes a remover um endereço',
        message: 'Você tem certeza que deseja remover este endereço do cadastro desta pessoa? Esta operação não pode ser desfeita.',
        ok: 'Sim',
        cancel: 'Não'
      }).then(() => {
        if (endereco.id) {
          this.removeItem('enderecos', this.pessoa.id, endereco.id)
        }
        this.pessoa.addresses.splice(this.pessoa.addresses.indexOf(endereco), 1)
      }).catch(() => {
      })
    }
  }
}
</script>
