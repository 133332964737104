<template>
  <div class="u-collapse" :class="{'open': isCollapsed}">
    <a @click="toggle" class="u-collapse-header">
      <slot name="header">
        <h3 class="font-avenir">
          {{ title }}
          <span v-if="desc">{{desc}}</span>
        </h3>
      </slot>
    </a>
    <div class="collapse-body" id="collapse0" aria-hidden="true">
      <slot>
        <label class="input-wrap">
          <u-input class="collapse-input" hide-underline :value="model" v-on:input="update"/>
        </label>
      </slot>
    </div>
  </div>
</template>

<script>
import {UInput} from 'uloc-vue'

export default {
  name: "Collapse",
  components: {UInput},
  props: {
    title: String,
    desc: String,
    value: {required: false},
    collapse: {required: false, type: Boolean, default: true},
  },
  data() {
    return {
      model: this.value,
      isCollapsed: this.collapse
    }
  },
  methods: {
    update(v) {
      this.model = v
      this.$emit('input', v)
    },
    toggle() {
      this.isCollapsed = !this.isCollapsed
      this.$nextTick(() => {
        this.$emit('collapse')
      })
    }
  }
}
</script>
